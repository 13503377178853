// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
		marginBottom: '20px'
  },
	postList: {
		padding: '30px 0 0'
	},
	postTitle: {
		color: '#084400',
		fontWeight: '500'
	},
  pos: {
    marginBottom: 12,
		fontSize: '0.75rem'
  },
});

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
	const classes = useStyles();

	const bull = <span className={classes.bullet}>•</span>;

  return (
    <Layout location={location} title={siteTitle}>
			<Header />
      <SEO title="All posts" />
			<Grid className={classes.postList}>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
					<Link to={node.fields.slug} key={node.fields.slug}>
						<Card className={classes.root}>
      				<CardContent>
    				    <Typography className={classes.postTitle} variant="h6" component="h2">
									{title}
    				    </Typography>
    				    <Typography className={classes.pos} color="textSecondary">
    				      {node.frontmatter.date}
    				    </Typography>
    				    <Typography variant="body2" component="p">
    				      {node.frontmatter.description || node.excerpt}
    				    </Typography>
    				  </CardContent>
    				</Card>
					</Link>
        )
      })}
			</Grid>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
